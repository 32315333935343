.inputSt {
    padding: 16px 15px 16px;
    height: 51px;
}

.labelSt {
    max-width: 760px;
    white-space: pre-wrap;
    color: rgb(74, 74, 74);
    font-weight: 400;
    font-family: Merriweather, Georgia, serif;
    font-size: 36px;
    text-align: center;
    justify-content: center;
}

.buttonbox {
    margin-left: auto;
    margin-right: auto;
}

.button {
    font-family: Merriweather, Georgia, serif;
    width: 120px;
    height: 41px;
}

.back-btn {
    position: absolute;
    left: 90px;
    top: 40%;
    width: 100px;
    height: 100px;
    display: block;
    opacity: 0.5;
    z-index: 6;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    background-size: 350px 100px;
}

@media (max-width: 700px) {
    .back-btn {
        display: none;
    }
}

.bod {
    background-color: #F7F7F7;
}

.credit-logo {
    width: 10px;
}

.container {
    display: flex;
    padding: 10px;
    margin: 0 10px;
}

@media (max-width: 640px) {
    .container {
        display: block;
        direction: column;
    }
}

.view {
    display: flex;
    direction: row;
    /* position: absolute; */
    margin-top: 60px;
    justify-content: center;
    margin-left: 40px;
}

@media (max-width: 640px) {
    .view {
        display: block;
        margin-left: -20px;
    }
}

.title {
    font-size: 2rem;
}

@media screen and (max-width: 400px) {
    .title {
        font-size: 1.6rem;
    }
}

.formCard {
    min-width: 400;
    display: flex;
}

@media screen and (max-width: 640px) {
    .formCard {
        min-width: auto;
        display: block;
    }
}

.checkbox {
    font-size: inherit;
}

@media screen and (max-width: 640px) {
    .checkbox {
        font-size: 10;
        margin-left: 10px;
    }
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.checkbox {
    font-size: 7;
}

@media screen and (max-width: 640px) {
    .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.checkbox {
        font-size: 6;
        margin-left: 20px;
    }
}

.Title {
    font-weight: 400;
    font-family: 'Merriweather', serif;
    font-size: 2.8rem;
    line-height: 36px;
    text-align: center;
}

.startimg {
    width: 500px;
    height: 300px;
}

@media screen and (max-width: 640px) {
    .startimg {
        width: 300px;
        height: 200px;
    }
}

.centerMyForm {
    justify-content: center;
    align-items: center;
}

.app-form {
    margin-left: 20px;
    margin-top: 20px;
    width: 100%;
}

@media screen and (max-width: 640px) {
    .app-form {
        margin-left: -30px;
        width: 100%;
    }
}

.dropdown {
    box-shadow: 0 1px 3px #807e7e;
    border: 1px solid #E8E8E8;
}

.suggestion {
    cursor: pointer;
    /* border-right: 1px solid black; */
    /* border-left: 1px solid black; */
    border-bottom: 1px solid #807e7e;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.suggestion:hover {
    background-color: #CAE2FA;
}